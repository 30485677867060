import Vue from 'vue'
import App from '../components/pixel/App.vue'
import axios from 'axios';

axios.defaults.headers.common['x-api-key'] = '9c6334a7-8215-46ff-8ec9-f442cdc43cac';
axios.defaults.headers.common['Accept'] = 'application/json';
Vue.prototype.$axios = axios;

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    render: h => h(App)
  }).$mount('#vue-px')
})